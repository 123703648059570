import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    loanData: [],
   
}

const loanSlice = createSlice({
    name: "loan",
    initialState: initialState, 
    reducers: {
        setLoanData: (state, value) => {
            console.log(value.payload)
            state.loanData = value.payload
        }
    }
})
export const {setLoanData}=loanSlice.actions;
export default loanSlice.reducer;
