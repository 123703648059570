import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, InputAdornment } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { editLoanApplicationData } from '../../../Services/Operations/AdminAPIs';
import { setLoanData } from '../../../Slices/EditLoanData';
import { baseUrl } from '../../../utils/baseUrl';

import axios from 'axios';
import toast from 'react-hot-toast';

const LoanApplicationForm = ({ formData: initialData, onClose }) => {
    const [loanApplicationList, setLoanApplicationList] = useState([]);

    const { token } = useSelector((state) => state.auth);
    const { loanData } = useSelector((state) => state.loan);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1); // Track current page
    const [hasMore, setHasMore] = useState(true); // Track if more data exists
    const [isLoading, setIsLoading] = useState(false); 
    console.log("data from redux", loanData)

    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
        fullName: '',
        mobile: '',
        aadharCardName: '',
        panCardName: '',
        aadharCardFile: null,
        panCardFile: null,
        loanCategory: '',
        requiredLoanAmount: '',
    });

   
    const getAllLoanApplications = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${baseUrl}/admin/list-loan-application?page=${page}&limit=10`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch loan applications');
            }
            const data = await response.json();
            console.log("all data ",data);
            setLoanApplicationList(data.data);
            setHasMore(data.data.length === 10);
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchGetAllLoanApplication = async (token) => {
        let result = [];
        try {
            const response = await axios.get(
                `https://financebazaar.vip/api/v1/admin/get-loan-application/${initialData.id}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                    },
                }
            );
            result = response?.data?.data;
            dispatch(setLoanData(result));
        } catch (error) {
            console.error("Error while fetching the loan application data", error.message);
            toast.error("Couldn't fetch loan application data");
        }
        return result;
    };



    useEffect(() => {
        if (initialData) {
            fetchGetAllLoanApplication(token).then((result) => {
                setFormData({
                    fullName: result.fullName || '',
                    mobile: result.mobile || '',
                    aadharCardName: result.aadharCard || '', // Aadhaar card URL
                    panCardName: result.panCard || '', // PAN card URL
                    loanCategory: result.loanCategory || '',
                    requiredLoanAmount: result.requiredLoanAmount || '',
                });
            });
        }
    }, [initialData, token]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleFileChange = (e, field) => {
        const file = e.target.files[0];
        if (file) {
            setFormData({
                ...formData,
                [`${field}Name`]: file.name,
                [`${field}File`]: file,
            });
        }
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            let formdataEdit = new FormData();
            if (loanData) {
                if (loanData.fullName != formData.fullName) {
                    formdataEdit.append("fullName", formData.fullName)
                }
                if (loanData.loanCategory != formData.loanCategory) {
                    formdataEdit.append("loanCategory", formData.loanCategory)
                }
                if (loanData.mobile != formData.mobile) {
                    formdataEdit.append("mobile", formData.mobile)
                }
                if (loanData.panCard != formData.panCardName) {
                    formdataEdit.append("panCard", formData.panCardName)
                }
                if (loanData.aadharCard != formData.aadharCardName) {
                    formdataEdit.append("adharCard", formData.aadharCardName)
                }
                if (loanData.requiredLoanAmount != formData.requiredLoanAmount) {
                    formdataEdit.append("requiredLoanAmount", formData.requiredLoanAmount)
                }
             
                const result = await editLoanApplicationData(formData, initialData.id, token)
                getAllLoanApplications();
                setFormData({
                    fullName: '',
                    mobile: '',
                    aadharCardName: '',
                    panCardName: '',
                    aadharCardFile: null,
                    panCardFile: null,
                    loanCategory: '',
                    requiredLoanAmount: '',
                  });
            }
            else {
                toast.error("No made change in Course")
            }
            return
        } catch (error) {
            console.log("error", error.message);
        }

        setLoading(false);





    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', maxHeight: "600px", overflowY: "scroll", scrollBehavior: "smooth" }}>
            <Typography variant="h6" gutterBottom>
                Edit Loan Application
            </Typography>

            <TextField
                fullWidth
                label="Full Name"
                name="fullName"
                value={formData.fullName}
                onChange={handleInputChange}
                sx={{ mb: 2 }}
            />

            <TextField
                fullWidth
                label="Mobile Number"
                name="mobile"
                value={formData.mobile}
                onChange={handleInputChange}
                sx={{ mb: 2 }}
            />

            {/* Aadhaar Card Input with image preview */}
            <Box sx={{ mb: 2, width: '100%' }}>
                {formData.aadharCardFile ? (
                    <img
                        src={URL.createObjectURL(formData.aadharCardFile)}
                        alt="Aadhaar Card"
                        style={{ width: '100px', marginTop: '8px' }}
                    />
                ) : formData.aadharCardName ? (
                    <img
                        src={formData.aadharCardName} // Aadhaar card URL from fetched data
                        alt="Aadhaar Card"
                        style={{ width: '100px', marginTop: '8px' }}
                    />
                ) : null}
                <TextField
                    fullWidth
                    label="Aadhar Card"
                    name="aadharCardName"
                    value={formData.aadharCardName || ''}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Button
                                    variant="contained"
                                    component="label"
                                    size="small"
                                >
                                    Upload
                                    <input
                                        type="file"
                                        hidden
                                        accept="image/*"
                                        onChange={(e) => handleFileChange(e, 'aadharCard')}
                                    />
                                </Button>
                            </InputAdornment>
                        ),
                    }}
                />

            </Box>

            {/* PAN Card Input with image preview */}
            <Box sx={{ mb: 2, width: '100%' }}>
                {formData.panCardFile ? (
                    <img
                        src={URL.createObjectURL(formData.panCardFile)}
                        alt="PAN Card"
                        style={{ width: '100px', marginTop: '8px' }}
                    />
                ) : formData.panCardName ? (
                    <img
                        src={formData.panCardName} // PAN card URL from fetched data
                        alt="PAN Card"
                        style={{ width: '100px', marginTop: '8px' }}
                    />
                ) : null}

                <TextField
                    fullWidth
                    label="PAN Card"
                    name="panCardName"
                    value={formData.panCardName || ''}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Button
                                    variant="contained"
                                    component="label"
                                    size="small"
                                >
                                    Upload
                                    <input
                                        type="file"
                                        hidden
                                        accept="image/*"
                                        onChange={(e) => handleFileChange(e, 'panCard')}
                                    />
                                </Button>
                            </InputAdornment>
                        ),
                    }}
                />

            </Box>

            <TextField
                fullWidth
                label="Loan Category"
                name="loanCategory"
                value={formData.loanCategory}
                onChange={handleInputChange}
                sx={{ mb: 2 }}
            />

            <TextField
                fullWidth
                label="Required Loan Amount"
                name="requiredLoanAmount"
                value={formData.requiredLoanAmount}
                onChange={handleInputChange}
                sx={{ mb: 2 }}
            />

            <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                sx={{ mt: 2 }}
                disabled={loading}
            >
                {loading ? 'Saving...' : 'Save Changes'}
            </Button>

            <Button
                variant="outlined"
                color="secondary"
                onClick={onClose}
                sx={{ mt: 1 }}
            >
                Cancel
            </Button>
        </Box>
    );
};

export default LoanApplicationForm;
