
const BASE_URL=`https://financebazaar.vip/api/v1`;


export const AuthEndPoint = {
  
    SIGNUP_API: BASE_URL + "/auth/signup",
    LOGIN_API: BASE_URL + "/admin/login",
    RESETPASSWORDTOKEN_API: BASE_URL + "/auth/reset-password-token",
    RESETPASSWORD_API: BASE_URL + "/auth/reset-password"


} 

export const AdminEndPoint={
    ADD_LOAN_WITH_ICON_API:BASE_URL+"/category/add-new",
    GET_ALL_LOAN_CATEGORY_API:BASE_URL+"/category/all-loan-category-list",
    EdIT_LOAN_CATEGORY_API:BASE_URL+"/admin/EDIT/:id",

    GET_ALL_LOAN_SUGGESTION_API:BASE_URL+"loan-category-suggestion/all-suggestion"
}
