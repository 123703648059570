import React from 'react';

const TermsAndConditions = () => {
    return (
        <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
            <h1>Terms & Conditions</h1>
            <p>Last Updated: [21-09-2024]</p>
            
            <h2>1. Introduction</h2>
            <p>
                Welcome to [Mk finance nidhi ltd] ("Finance bazaar"), owned and operated by [Mk finance nidhi ltd]. These Terms and Conditions ("Terms") govern your use of the App, which enables users to securely share, manage, and store financial documents. By accessing or using the App, you agree to be bound by these Terms. If you do not agree to these Terms, please refrain from using the App.
            </p>

            <h2>2. Eligibility</h2>
            <p>
                You must be at least 18 years old to use the App. By using the App, you represent that you have the legal capacity to enter into binding contracts and that all information you provide is accurate and current.
            </p>

            <h2>3. Account Registration</h2>
            <ul>
                <li>To access certain features of the App, you must create an account.</li>
                <li>You are responsible for maintaining the confidentiality of your login credentials and for all activities that occur under your account.</li>
                <li>If you suspect unauthorized use of your account, you must notify us immediately.</li>
                <li>We reserve the right to suspend or terminate your account at any time for violations of these Terms or for any reason at our sole discretion.</li>
            </ul>

            <h2>4. Permitted Use</h2>
            <p>
                The App is provided for the purpose of sharing, storing, and managing financial documents securely. You agree not to:
            </p>
            <ul>
                <li>Use the App for illegal purposes or activities.</li>
                <li>Upload, post, or share any content that infringes on the intellectual property rights of others.</li>
                <li>Misuse the App in any manner that could damage, disable, or impair the App’s functionality.</li>
                <li>Attempt to hack or interfere with the security of the App.</li>
            </ul>

            <h2>5. User Content</h2>
            <ul>
                <li>
                    By uploading documents or other content to the App, you represent that you own or have the necessary permissions to share them.
                </li>
                <li>
                    You retain all ownership rights to your content, but you grant [Mk finance nidhi ltd] a non-exclusive, royalty-free license to use, store, display, and share the content as necessary to provide the App’s services.
                </li>
                <li>
                    [Mk finance nidhi ltd] is not responsible for the accuracy or legality of any documents shared on the App.
                </li>
            </ul>

            <h2>6. Privacy Policy</h2>
            <p>
                Your privacy is important to us. Our [Privacy Policy] outlines how we collect, use, and protect your personal information. By using the App, you agree to the terms of the Privacy Policy.
            </p>

            <h2>7. Data Security</h2>
            <ul>
                <li>We take security seriously and implement reasonable measures to protect the confidentiality and integrity of your documents.</li>
                <li>However, no data transmission over the internet is completely secure. By using the App, you acknowledge that you assume any risk of unauthorized access, data loss, or breach.</li>
                <li>You are encouraged to back up your data independently.</li>
            </ul>

            <h2>8. Third-Party Services</h2>
            <p>
                The App may include integrations or links to third-party services. We are not responsible for the content, privacy policies, or practices of these third-party services. You are encouraged to review their respective terms and conditions.
            </p>

            <h2>9. Fees and Payments</h2>
            <ul>
                <li>Certain features of the App may require payment. If applicable, you will be informed of any fees before being charged.</li>
                <li>All fees are non-refundable unless otherwise specified.</li>
                <li>You agree to provide accurate billing information and authorize us to charge any associated fees to your provided payment method.</li>
            </ul>

            <h2>10. Intellectual Property</h2>
            <ul>
                <li>All software, design, graphics, trademarks, and content related to the App are the intellectual property of [Mk finance nidhi ltd] or its licensors.</li>
                <li>You may not copy, modify, distribute, or reverse-engineer any part of the App without prior written consent from [Mk finance nidhi ltd].</li>
            </ul>

            <h2>11. Limitation of Liability</h2>
            <ul>
                <li>[Mk finance nidhi ltd] is not liable for any damages, including but not limited to lost profits, data loss, or business interruption, arising from your use of or inability to use the App.</li>
                <li>The App is provided "as is," without warranties of any kind, either express or implied.</li>
            </ul>

            <h2>12. Indemnification</h2>
            <p>
                You agree to indemnify, defend, and hold harmless [Mk finance nidhi ltd], its affiliates, officers, and employees from and against any claims, liabilities, damages, losses, or expenses, including legal fees, arising out of your use of the App, your violation of these Terms, or your violation of any rights of another.
            </p>

            <h2>13. Termination</h2>
            <ul>
                <li>[Mk finance nidhi ltd] reserves the right to terminate or suspend your access to the App at any time, with or without cause, and without prior notice.</li>
                <li>Upon termination, your right to use the App will immediately cease, and we may delete or disable access to any documents you have uploaded.</li>
            </ul>

            <h2>14. Governing Law</h2>
            <p>
                These Terms shall be governed by and construed in accordance with the laws of [India/Andhra Pradesh], without regard to its conflict of law provisions. Any disputes arising under or in connection with these Terms shall be resolved exclusively in the courts located in [Andhra Pradesh].
            </p>

            <h2>15. Amendments to Terms</h2>
            <p>
                We may revise these Terms at any time. Any changes will be posted on the App, and continued use of the App following the posting of changes constitutes your acceptance of such changes.
            </p>

            <h2>16. Contact Information</h2>
            <p>
                If you have any questions or concerns regarding these Terms, please contact us at: [Mk finance nidhi ltd] <br />
                [mkfinancenidhi@gmail.com] <br />
                [+917777974466]
            </p>

            <h2>17. Miscellaneous</h2>
            <ul>
                <li>If any provision of these Terms is found to be unenforceable, the remainder of the Terms shall continue to be valid.</li>
                <li>These Terms constitute the entire agreement between you and [Mk finance nidhi ltd] regarding your use of the App.</li>
            </ul>
        </div>
    );
};

export default TermsAndConditions;
