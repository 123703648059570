import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
            <h4>PRIVACY POLICY</h4>
            <h6>Last updated October 28, 2024</h6>

            <p>
                <strong>About Financer Bazar:</strong> 
                Financer Bazar provides a document-processing platform to support financiers in reviewing and discussing 
                loan applications. This app is not a lender and does not offer financial products or services. 
                We offer a secure, user-friendly way to manage and share necessary documentation for loan consideration.
            </p>
            <br/>
            <br/>

            <p>
                <strong>Privacy Policy Update (For Compliance):</strong> <br />
                At Financer Bazar, we prioritize the privacy and security of our users. We collect, store, and process 
                user-provided documents solely for the purpose of facilitating discussions between financiers and applicants. 
                We do not share, sell, or misuse user data under any circumstances. Documents are encrypted and can only 
                be accessed by authorized users within the app.
            </p>
            <br/>
            <br/>

            <p>
                <strong>Data Use:</strong> 
                All information provided is intended to support financiers in assessing loan documentation. 
                Financer Bazar itself does not use or alter the content for any other purpose.
            </p>
            <br/>
            <br/>
            <p>
                <strong>Disclaimer:</strong> 
                Financer Bazar is a document-processing application that helps financiers evaluate and discuss loan 
                applications by providing a secure platform for document sharing. This app does not directly offer or 
                approve loans, nor does it influence any loan terms or decisions. All financing decisions are solely 
                at the discretion of the individual financier based on the documents submitted. Please consult a 
                financial advisor for any loan-related guidance or decisions.
            </p>
            <br/>
            <br/>
            <p>
                <strong>Key Features:</strong>
                <ul>
                    <li>Secure Document Upload: Financers can review documents necessary for making informed loan decisions.</li>
                    <li>Easy Communication: Facilitates discussions between financiers and applicants based on document submissions.</li>
                    <li>Streamlined Processing: All documents are handled with high security and privacy standards.</li>
                    <li>Disclaimer: Financer Bazar is strictly for document management purposes and does not directly provide, 
                        approve, or endorse loans. All loan decisions are at the sole discretion of the financiers and are subject to 
                        applicable laws and regulations.
                    </li>
                </ul>
            </p>
            <br/>
            <br/>
            <p>
                <strong>Terms & Conditions</strong> <br />
                <strong>Last Updated:</strong> [21-09-2024]
            </p>

            <p>
                <strong>1. Introduction:</strong> 
                Welcome to [Mk finance nidhi ltd] ("Finance bazaar"), owned and operated by [Mk finance nidhi ltd]. 
                These Terms and Conditions ("Terms") govern your use of the App, which enables users to securely share, 
                manage, and store financial documents. By accessing or using the App, you agree to be bound by these Terms. 
                If you do not agree to these Terms, please refrain from using the App.
            </p>
            <br/>
            <br/>
            <p>
                <strong>2. Eligibility:</strong>
                <ul>
                    <li>You must be at least 18 years old to use the App.</li>
                    <li>By using the App, you represent that you have the legal capacity to enter into binding contracts 
                        and that all information you provide is accurate and current.
                    </li>
                </ul>
            </p>
            <br/>
            <br/>
            <p>
                <strong>3. Account Registration:</strong>
                <ul>
                    <li>To access certain features of the App, you must create an account.</li>
                    <li>You are responsible for maintaining the confidentiality of your login credentials and for all 
                        activities that occur under your account.
                    </li>
                    <li>If you suspect unauthorized use of your account, you must notify us immediately.</li>
                    <li>We reserve the right to suspend or terminate your account at any time for violations of these Terms 
                        or for any reason at our sole discretion.
                    </li>
                </ul>
            </p>
            <br/>
            <br/>
            <p>
                <strong>4. Permitted Use:</strong> 
                The App is provided for the purpose of sharing, storing, and managing financial documents securely. 
                You agree not to:
                <ul>
                    <li>Use the App for illegal purposes or activities.</li>
                    <li>Upload, post, or share any content that infringes on the intellectual property rights of others.</li>
                    <li>Misuse the App in any manner that could damage, disable, or impair the App’s functionality.</li>
                    <li>Attempt to hack or interfere with the security of the App.</li>
                </ul>
            </p>
            <br/>
            <br/>
            <p>
                <strong>5. User Content:</strong>
                <ul>
                    <li>By uploading documents or other content to the App, you represent that you own or have the necessary 
                        permissions to share them.
                    </li>
                    <li>You retain all ownership rights to your content, but you grant [Mk finance nidhi ltd] a non-exclusive, 
                        royalty-free license to use, store, display, and share the content as necessary to provide the App’s services.
                    </li>
                    <li>[Mk finance nidhi ltd] is not responsible for the accuracy or legality of any documents shared on the App.</li>
                </ul>
            </p>
            <br/>
            <br/>
            <p>
                <strong>6. Privacy Policy:</strong> 
                Your privacy is important to us. Our [Privacy Policy] outlines how we collect, use, and protect your personal 
                information. By using the App, you agree to the terms of the Privacy Policy.
            </p>

            <p>
                <strong>7. Data Security:</strong>
                <ul>
                    <li>We take security seriously and implement reasonable measures to protect the confidentiality and 
                        integrity of your documents.
                    </li>
                    <li>No data transmission over the internet is completely secure. By using the App, you acknowledge that you 
                        assume any risk of unauthorized access, data loss, or breach.
                    </li>
                    <li>You are encouraged to back up your data independently.</li>
                </ul>
            </p>
            <br/>
            <br/>
            <p>
                <strong>8. Third-Party Services:</strong> 
                The App may include integrations or links to third-party services. We are not responsible for the content, 
                privacy policies, or practices of these third-party services. You are encouraged to review their respective 
                terms and conditions.
            </p>

            <p>
                <strong>9. Fees and Payments:</strong> 
                <ul>
                    <li>Certain features of the App may require payment. If applicable, you will be informed of any fees before 
                        being charged.
                    </li>
                    <li>All fees are non-refundable unless otherwise specified.</li>
                    <li>You agree to provide accurate billing information and authorize us to charge any associated fees to your 
                        provided payment method.
                    </li>
                </ul>
            </p>
            <br/>
            <br/>
            <p>
                <strong>Contact Information:</strong> 
                <br />
                [Mk finance nidhi ltd] <br />
                [mkfinancenidhi@gmail.com] <br />
                [+917777974466]
            </p>
        </div>
    );
};

export default PrivacyPolicy;
