import React, { useEffect, useState } from 'react';
import {
    Tabs, Tab, Accordion, Box, AccordionDetails, IconButton, AccordionSummary, Typography, Card,
    CardContent, CardMedia, List, ListItem, ListItemText, Divider, Button, Dialog,
    DialogTitle, DialogContent, DialogActions, Stack, Chip, TextField, InputAdornment,
    Grid, MenuItem, Select
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useParams } from 'react-router-dom';
import { baseUrl } from '../utils/baseUrl';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import { useSelector } from 'react-redux';
const LoanApplicationDetails = () => {
    const { token } = useSelector((state) => state.auth);
    const { id } = useParams();
    const [expandedFinancerId, setExpandedFinancerId] = useState(null);
    const [data, setData] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [openOtherDocModal, setOpenOtherDocModal] = useState(false);
    const [selectedDoc, setSelectedDoc] = useState(null);
    const [file, setFile] = useState()
    const [loading, setLoading] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);

    const getLoanApplicationDetails = async () => {
        try {
            const response = await fetch(`${baseUrl}/admin/view-loan-application/${id}`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch loan application details');
            }

            const data = await response.json();
            setData(data);
        } catch (error) {
            console.error('Error:', error);
        }
    };


    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };


    const getFilteredFinancers = () => {
        if (!data || !data.loanApplication.financers) return [];
        const statusMap = ['accepted', 'rejected', 'documentRequested'];
        const selectedStatus = statusMap[selectedTab];
        return data.loanApplication.financers.filter(
            (financer) => financer.FinancerLoanOffer.status === selectedStatus
        );
    };

    useEffect(() => {
        getLoanApplicationDetails();
    }, [id]);

    const handleFinancerClick = (financerId) => {
        setExpandedFinancerId(financerId === expandedFinancerId ? null : financerId);
    };

    const handleAccordionChange = (financerId) => {
        setExpandedFinancerId(financerId === expandedFinancerId ? null : financerId);
    };

    const handleOpenModal = (docType) => {
        setSelectedDoc(docType);
        setOpenModal(true);
    };

    const handleOpenOtherDocModal = (docUrl) => {
        setSelectedDoc(docUrl);
        setOpenOtherDocModal(true);
    };
    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedDoc(null);
    };

    const handleCloseOtherDocModal = () => {
        setOpenOtherDocModal(false);
        setSelectedDoc(null);
    };

    const handleUploadDocs = async (financerId, loanApplicationId) => {
        try {
            if (loading) return;
            if (!file) {
                throw new Error('Please select file');
            }
            setLoading(true);
            const formDataToSend = new FormData();
            formDataToSend.append('file', file);
            formDataToSend.append('loanApplicationId', loanApplicationId);
            formDataToSend.append('financerId', financerId);

            const response = await fetch(`${baseUrl}/admin/upload-more-docs`, {
                method: 'PATCH',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: formDataToSend,
            });
            if (!response.ok) {
                throw new Error('Failed to upload documents.');
            }
            const data = await response.json();
            alert("Documents uploaded")
            setFile("")
            getLoanApplicationDetails();

        } catch (error) {
            console.error('Error:', error);
            alert(error.message)
        } finally {
            setLoading(false);
        }
    }

    const handleStatusChange = async (newStatus) => {
        try {
            const response = await fetch(`${baseUrl}/admin/update-loan-application-status/${id}?status=${newStatus}`, {
                method: 'PATCH',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to update document status.');
            }
            alert(`status updated successfully.`);
            getLoanApplicationDetails();

        } catch (error) {
            console.error('Error:', error);
            alert('Failed to update status.');
        }
    };
    return (
        <Box sx={{ p: 2 }}>

            {data?.loanApplication &&
                <>
                    <Card sx={{ mb: 2, borderRadius: "10px", boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)" }}>
                        <CardContent>
                            <Typography variant="h5" component="div" gutterBottom sx={{
                                backgroundColor: "#1565c0",
                                padding: "10px",
                                color: "#fff",
                                borderRadius: "7px",
                                textAlign: "center"
                            }}>
                                Loan Application Details
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item sm={12} md={6}>
                                    Status
                                    <Select
                                        value={data?.loanApplication?.status || ''}
                                        onChange={(e) => handleStatusChange(e.target.value)}
                                        sx={{ mt: 1, ml: 1 }}
                                    >
                                        <MenuItem value="pending">Pending</MenuItem>
                                        <MenuItem value="approved">Approved</MenuItem>
                                        <MenuItem value="rejected">Rejected</MenuItem>
                                    </Select>
                                </Grid>
                            </Grid>

                            <Typography variant="body1" gutterBottom>
                                <strong>Name:</strong> {data?.loanApplication?.fullName}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                <strong>Mobile:</strong> {data?.loanApplication.mobile}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                <strong>Loan Category:</strong> {data?.loanApplication.loanCategory}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                <strong>Required Loan Amount:</strong> {data?.loanApplication.requiredLoanAmount}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                <strong>Application Date:</strong> {new Date(data?.loanApplication.createdAt).toLocaleDateString('en-GB', {
                                    day: 'numeric',
                                    month: 'long',
                                    year: 'numeric'
                                })} at {new Date(data?.loanApplication?.createdAt).toLocaleTimeString()}
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid sm={6} md={3} sx={{ mt: 2 }}>
                                    <Typography variant="h6">Aadhar Card</Typography>
                                    <IconButton color="primary" sx={{ fontSize: "12px", display: "flex", flexDirection: "row", gap: "5px" }} onClick={() => handleOpenModal('aadhar')}>
                                        View Doc <VisibilityIcon />
                                    </IconButton>
                                </Grid>
                                <Grid sm={6} md={3} sx={{ mt: 2 }}>
                                    <Typography variant="h6">Pan Card</Typography>
                                    <IconButton color="primary" sx={{ fontSize: "12px", display: "flex", flexDirection: "row", gap: "5px" }} onClick={() => handleOpenModal('pan')}>
                                        View Doc <VisibilityIcon />
                                    </IconButton>
                                </Grid>
                                {
                                    data?.documents?.map((item, index) => (
                                        <Grid sm={6} md={3} sx={{ mt: 2 }}>
                                            <Typography variant="h6">Other Documents</Typography>
                                            <IconButton color="primary" sx={{ fontSize: "12px", display: "flex", flexDirection: "row", gap: "5px" }} onClick={() => handleOpenOtherDocModal(item.url)}>
                                                View Doc {index + 1} <VisibilityIcon />
                                            </IconButton>
                                        </Grid>
                                    ))
                                }

                            </Grid>
                        </CardContent>
                    </Card>

                    <Typography variant="h6" sx={{ mb: 2 }}>
                        List of Financers
                    </Typography>
                    <Tabs
                        value={selectedTab}
                        onChange={handleTabChange}
                        aria-label="Financer Status Tabs"
                        sx={{ backgroundColor: "#1976d2", minHeight: '48px' }}
                        TabIndicatorProps={{ style: { backgroundColor: 'white', height: '3px' } }}
                    >
                        <Tab
                            sx={{
                                color: selectedTab === 0 ? '#c0ebcb' : '#90caf9',
                                fontWeight: selectedTab === 0 ? 'bold' : 'normal',
                                backgroundColor: selectedTab === 0 ? '#1565c0' : 'transparent',
                                '&.Mui-selected': {
                                    color: '#c0ebcb',
                                    backgroundColor: '#1565c0',
                                  },
                                '&:hover': {
                                    backgroundColor: '#1e88e5',
                                },
                                minHeight: '48px',
                            }}
                            label="Accepted"
                        />
                        <Tab
                            sx={{
                                color: selectedTab === 1 ? '#c0ebcb' : '#90caf9',
                                fontWeight: selectedTab === 1 ? 'bold' : 'normal',
                                backgroundColor: selectedTab === 1 ? '#1565c0' : 'transparent',
                                '&.Mui-selected': {
                                    color: '#c0ebcb',
                                    backgroundColor: '#1565c0',
                                  },
                                '&:hover': {
                                    backgroundColor: '#1e88e5',
                                },
                                minHeight: '48px',
                            }}
                            label="Rejected"
                        />
                        <Tab
                            sx={{
                                color: selectedTab === 2 ? '#c0ebcb' : '#90caf9',
                                fontWeight: selectedTab === 2 ? 'bold' : 'normal',
                                backgroundColor: selectedTab === 2 ? '#1565c0' : 'transparent',
                                '&.Mui-selected': {
                                    color: '#c0ebcb',
                                    backgroundColor: '#1565c0',
                                  },
                                '&:hover': {
                                    backgroundColor: '#1e88e5',
                                },
                                minHeight: '48px',
                            }}
                            label="Asked"
                        />
                    </Tabs>

                    <List>
                        {data && getFilteredFinancers()?.map((financer) => (
                            <React.Fragment key={financer.financerId}>
                                <ListItem>
                                    <ListItemText
                                        primary={`${financer.firstName} ${financer.lastName}`}
                                        secondary={`${financer.email} | ${financer.mobile}`}
                                    />
                                    <ListItemText
                                        secondary={`Created At: ${new Date(financer?.FinancerLoanOffer?.createdAt).toLocaleDateString('en-GB', {
                                            day: '2-digit',
                                            month: '2-digit',
                                            year: 'numeric'
                                        })}`}
                                    />
                                    <IconButton color="primary" onClick={() => handleFinancerClick(financer?.financerId)}>
                                        <VisibilityIcon />
                                    </IconButton>
                                </ListItem>
                                <Divider />
                                <Accordion expanded={expandedFinancerId === financer.financerId} onChange={() => handleAccordionChange(financer.financerId)}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="h6">Financer Details</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography variant="body1" gutterBottom>
                                            <strong>Email:</strong> {financer.email}
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            <strong>Mobile:</strong> {financer.mobile}
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            <strong>Financer Loan Offer Status:</strong> <Chip label={financer.FinancerLoanOffer.status} color="success" />
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            <strong>Monthly Interest Rate:</strong> {financer.FinancerLoanOffer.monthlyInterestRate}%
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            <strong>Yearly Interest Rate:</strong> {financer.FinancerLoanOffer.yearlyInterestRate}%
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            <strong>Document Requests:</strong>
                                        </Typography>
                                        <List>
                                            {financer?.FinancerLoanOffer?.documentRequests?.map((request, index) => (
                                                <ListItem key={index}>
                                                    {request.type === "text" ? (
                                                        <ListItemText
                                                            primary={"Message"}
                                                            secondary={ 
                                                                <Box>
                                                                    <Typography sx={{ backgroundColor: request.status === "Pending" ? '#ffeb3b' : '#4caf50', padding: '5px', borderRadius: '5px', display: 'inline-block' }}>
                                                                        Status: {request.status}
                                                                    </Typography>
                                                                    <Typography variant="body2" sx={{ mt: 1 }}>{request.content}</Typography>
                                                                </Box>
                                                            }
                                                        />
                                                    ) : (
                                                        <>
                                                            <Typography variant="body1" gutterBottom>
                                                                <strong>Voice Notes:</strong>
                                                            </Typography>
                                                            <List>
                                                                {financer.FinancerLoanOffer.voiceNotes.map((note, index) => (
                                                                    <ListItem key={index}>
                                                                        <a href={note.url} target="_blank" rel="noopener noreferrer">
                                                                            <IconButton color="primary">
                                                                                Play <AudioFileIcon />
                                                                            </IconButton>
                                                                        </a>
                                                                    </ListItem>
                                                                ))}
                                                            </List>
                                                        </>
                                                    )}
                                                </ListItem>
                                            ))}
                                        </List>

                                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                            <TextField
                                                fullWidth
                                                label="Upload Docs"
                                                name="Add More Docs"
                                                value={file?.name || ''}
                                                InputLabelProps={{ shrink: !!file?.name }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                component="label"
                                                                sx={{ marginLeft: 1 }}
                                                            >
                                                                Select File
                                                                <input type="file" hidden onChange={(e) => setFile(e.target.files[0])} />
                                                            </Button>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                sx={{ mr: 1, borderRadius: '8px', '& .MuiOutlinedInput-root': { borderRadius: '8px' } }}
                                            />
                                            <Button disabled={loading} variant="contained" color="primary" onClick={() => handleUploadDocs(financer?.FinancerLoanOffer?.financerId, financer?.FinancerLoanOffer?.loanApplicationId)}>
                                                Upload
                                            </Button>
                                        </Box>

                                        <Typography variant="body1" gutterBottom>
                                            <strong>Documents:</strong>
                                        </Typography>
                                        <List>
                                            {financer?.FinancerLoanOffer?.documents?.map((doc, index) => (
                                                <ListItem key={index}>
                                                    <a href={doc} target="_blank" rel="noopener noreferrer">View Document {index + 1}</a>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </AccordionDetails>
                                </Accordion>
                            </React.Fragment>
                        ))}
                    </List>

                    <Dialog open={openModal} onClose={handleCloseModal} fullWidth maxWidth="md">
                        <DialogTitle>{selectedDoc === 'aadhar' ? 'Aadhar Card' : 'PAN Card'}</DialogTitle>
                        <DialogContent>
                            <CardMedia
                                component="img"
                                image={selectedDoc === 'aadhar' ? data?.aadharCardUrl : data?.panCardUrl}
                                alt={selectedDoc === 'aadhar' ? 'Aadhar Card' : 'PAN Card'}
                                sx={{ width: '100%', height: 'auto' }}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseModal} color="primary">
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={openOtherDocModal} onClose={handleCloseOtherDocModal} fullWidth maxWidth="md">
                        <DialogTitle>{"Other Document"}</DialogTitle>
                        <DialogContent>
                            <CardMedia
                                component="img"
                                image={selectedDoc}
                                alt={'Docs'}
                                sx={{ width: '100%', height: 'auto' }}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseOtherDocModal} color="primary">
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            }
        </Box>
    );
};

export default LoanApplicationDetails;
