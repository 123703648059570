import React, { useEffect, useState } from 'react';
import {
    Table, Button, Box, Divider, TableBody, TableCell, TableHead, TableRow, IconButton, Paper, Container, Typography, Dialog, DialogTitle, DialogContent,
    DialogActions,
    Grid,
} from '@mui/material';
import { useSelector } from 'react-redux';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { deleteFinencer } from '../../../Services/Operations/AdminAPIs';

import { baseUrl } from '../../../utils/baseUrl';
const ManageFinanceList = () => {
    const { token } = useSelector((state) => state.auth);

    const [financersList, setFinancerList] = useState([])
    const [financerDetailsModalOpen, setFinancerDetailsModelOpen] = useState(false)
    const [selectedFinancer, setSelectedFinancer] = useState(null)

    const getAllFinancer = async () => {
        try {
            const response = await fetch(`${baseUrl}/admin/get-financer`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to submit loan application');
            }

            const data = await response.json()
            setFinancerList(data.data)

        } catch (error) {
            console.error('Error:', error);
        }
    }
    useEffect(() => {
     
        getAllFinancer()
    }, [])

    const handleFinancerDetails = (financer) => {
        setSelectedFinancer(financer)
        setFinancerDetailsModelOpen(true)
    }

    const handleCloseModal = () => {
        setFinancerDetailsModelOpen(false)
    }

    const handleDeleteFinencers=async(id)=>{
        await deleteFinencer(id,token)
        getAllFinancer();
    }

    return (
        <Container>
            <Paper style={{ padding: 16 }}>
                <Typography variant="h5" gutterBottom>Manage Financers</Typography>

                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>S.No</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Surname</TableCell>
                            <TableCell>Mobile</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>View</TableCell>
                            <TableCell>Delete</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {financersList.map((row, index) => (
                            <TableRow key={row.id}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{row.firstName}</TableCell>
                                <TableCell>{row.lastName}</TableCell>
                                <TableCell>{row.mobile}</TableCell>
                                <TableCell>{row.email}</TableCell>
                                <TableCell>
                                    <IconButton color="primary" aria-label="view" onClick={() => handleFinancerDetails(row)}>
                                        <VisibilityIcon />
                                    </IconButton>
                                </TableCell>
                                <TableCell>
                                    <IconButton
                                        onClick={() => handleDeleteFinencers(row.id)}
                                        aria-label="Delete"
                                    >
                                        <DeleteForeverIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

            </Paper>
            <Dialog open={financerDetailsModalOpen} onClose={handleCloseModal} fullWidth maxWidth="md">
                <DialogTitle>Financer Details</DialogTitle>
                {selectedFinancer &&

                    <DialogContent>
                        <Box>
                            <Grid container spacing={2}>
                                <Grid item sm={12} md={4}>
                                    Financer Name <br />
                                    {selectedFinancer?.firstName + " " + selectedFinancer?.lastName}
                                </Grid>
                                <Grid item sm={12} md={4}>
                                    Mobile <br />
                                    {selectedFinancer?.mobile || "N/A"}
                                </Grid>
                                <Grid item sm={12} md={4}>
                                    Email <br />
                                    {selectedFinancer?.email || "N/A"}
                                </Grid>
                            </Grid>

                            <br />
                            <Divider />
                            <br />
                            <Grid container spacing={2}>
                                <Grid item sm={12} md={4}>
                                    Business Name : {selectedFinancer?.businessName || "N/A"}
                                </Grid>
                                <Grid item sm={12} md={4}>
                                    Business Value : {selectedFinancer?.businessValue || "N/A"}
                                </Grid>
                                <Grid item sm={12} md={4}>
                                    Provide Loan  For : {selectedFinancer?.businessValue || "N/A"}
                                </Grid>
                            </Grid>


                            <br />
                            <Divider />
                            <p>
                                Address <br />
                                Add : {selectedFinancer?.residentialAddress || "N/A"} <br />
                                <Divider />

                                <Grid container spacing={2}>
                                    <Grid item sm={12} md={4}>
                                        City : {selectedFinancer?.city || "N/A"}
                                    </Grid>
                                    <Grid item sm={12} md={4}>
                                        State : {selectedFinancer?.state || "N/A"}
                                    </Grid>
                                    <Grid item sm={12} md={4}>
                                        Pin Code : {selectedFinancer?.zip || "N/A"}
                                    </Grid>
                                </Grid>

                            </p>
                        </Box>
                    </DialogContent>
                }

                <DialogActions>
                    <Button onClick={handleCloseModal} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default ManageFinanceList;
