import React, { useEffect, useState } from 'react';
import {
    Table, Button, Box, Divider, TableBody, TableCell, TableHead, TableRow, IconButton, Paper, Container, Typography, Dialog, DialogTitle, DialogContent,
    DialogActions,
    Grid,
} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { deleteLoanSuggestion } from '../../../Services/Operations/AdminAPIs';

import { useSelector } from 'react-redux';

import { baseUrl } from '../../../utils/baseUrl';
const LoanSuggestion = () => {

    const { token } = useSelector((state) => state.auth)
    const [loanSuggestionList, setLoanSuggestionList] = useState([])
    const [financerDetailsModalOpen, setFinancerDetailsModelOpen] = useState(false)
    const [selectedFinancer, setSelectedFinancer] = useState(null)
    const getAllFinancer = async () => {
        try {
            const response = await fetch(`${baseUrl}/loan-category-suggestion/all-suggestion`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to submit loan application');
            }

            const data = await response.json()
            console.log("data", data);
            setLoanSuggestionList(data.data)

        } catch (error) {
            console.error('Error:', error);
        }
    }
    useEffect(() => {


        getAllFinancer()
    }, [])

    const handleDeleteLaonCategory = async (loanName) => {
        await deleteLoanSuggestion(loanName, token)
        getAllFinancer();
    }

    return (
        <Container>
            <Paper style={{ padding: 16 }}>
                <Typography variant="h5" gutterBottom>ALL Loan Suggestion List</Typography>

                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>S.No</TableCell>
                            <TableCell>Category</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loanSuggestionList.map((row, index) => (
                            <TableRow key={row.id}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{row.categoryName}</TableCell>
                                <TableCell>
                                    <IconButton
                                        onClick={() => handleDeleteLaonCategory(row.categoryName)}
                                        aria-label="Delete"
                                    >
                                        <DeleteForeverIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>

        </Container>
    );
};

export default LoanSuggestion;
