import React, { useState } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { getLogin } from '../../../Services/Operations/authAPI';
import { useSelector, useDispatch } from 'react-redux';

const Login = () => {
  const [email, setEmail] = useState(''); 
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const navigate = useNavigate();


  const dispatch = useDispatch();


  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (e.target.value) setEmailError('');
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (e.target.value) setPasswordError('');
  };

  const handleLogin = () => {
    let valid = true;

    if (!email) {
      setEmailError('Please enter an email');
      valid = false;
    }
    if (!password) {
      setPasswordError('Please enter a password');
      valid = false;
    }

    if (valid) {
      dispatch(getLogin(email, password,navigate))
      setEmail('');
      setPassword("");
    
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        padding: '0 20px',
      }}
    >
      <Box
        sx={{
          maxWidth: 400,
          width: '100%',
          padding: 4,
          border: '1px solid #ddd',
          borderRadius: 2,
          boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
        }}
      >
        <Typography variant="h5" textAlign="center" mb={2}>
          Login
        </Typography>

        <TextField
          label="Email"
          variant="outlined"
          type="email"
          fullWidth
          value={email}
          onChange={handleEmailChange}
          sx={{ mb: 2 }}
          error={Boolean(emailError)}
          helperText={emailError}
        />

        <TextField
          label="Password"
          variant="outlined"
          type="password"
          fullWidth
          value={password}
          onChange={handlePasswordChange}
          sx={{ mb: 2 }}
          error={Boolean(passwordError)}
          helperText={passwordError}
        />

        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleLogin}
        >
          Login
        </Button>


      </Box>
    </Box>
  );
};

export default Login;
