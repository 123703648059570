import { combineReducers } from "@reduxjs/toolkit";

import authReducer from "../Slices/Auth";
import profileReducer from "../Slices/Profile";
import loanReducers from "../Slices/EditLoanData";

const rootReducer=combineReducers({
    auth:authReducer,
    profile:profileReducer,
    loan:loanReducers
})

export default rootReducer;