import React from "react";
import { Routes, Route } from "react-router-dom";
import LoginPage from "./pages/Login";
import AdminDashboard from "./pages/AdminDashnoard";
import ManageFinanceList from "./Components/Core/AdminDashboard/ManageFinancerList";
import LoanAppliactionsList from "./Components/Core/AdminDashboard/LoanApplication";
import LoanCategory from "./Components/Core/AdminDashboard/LoanCategory";
import LoanApplicationForm from "./Components/Core/AdminDashboard/LoanApplicationForm";
import LoanApplicationDetails from "./pages/LoanApplicationDetails";
import LoanSuggestion from "./Components/Core/AdminDashboard/LoanSuggestion";
import TermsAndConditions from "./pages/TermsAndCondition";
import PrivacyPolicy from "./pages/PrivacyPolicy";

function App() {
  return (

    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="/terms-and-condition" element={<TermsAndConditions />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/admin/dashboard" element={<AdminDashboard />}>
        <Route path="manage-financers" element={<ManageFinanceList />}> </Route>
        <Route path="manage-loan-application" element={<LoanAppliactionsList />}></Route>
        <Route path="view-loan-application-details/:id" element={<LoanApplicationDetails />}></Route>
        <Route path="manage-loan-category" element={<LoanCategory />}> </Route>
        <Route path="create-loan-application" element={<LoanApplicationForm />}> </Route>
        <Route path="Loan-Suggestion" element={<LoanSuggestion />}></Route>
      </Route>
    </Routes>

  );
}

export default App;
