import React, { useEffect, useState } from 'react';
import { TextField, Button, Table, TableBody, TableCell, TableHead, TableRow, IconButton, Paper, Container, Typography } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { addLoan, FetchAllLoanCategory, deleteLoanCategory } from '../../../Services/Operations/AdminAPIs';
import { useSelector } from 'react-redux';

const LoanCategory = () => {
    // State to handle form data
    const { token } = useSelector((state) => state.auth);

    const [loanCategory, setLoanCategory] = useState("");
    const [loanIcon, setLoanIcon] = useState('');
    const [loading, setLoading] = useState(false);
    const [loanList, setLoanList] = useState([]); // Corrected state initialization

    const fetchLoanData = async () => {
        setLoading(true);
        const result = await FetchAllLoanCategory();
        setLoanList(result);
        console.log(result);
        setLoading(false);
    };

    useEffect(() => {

        fetchLoanData();
    }, []);

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = {
            categoryName: loanCategory,
            categoryIconUrl: loanIcon
        };
        setLoading(true);
        const result = await addLoan(formData, token);
        setLoading(false);

        // Reset form fields
        setLoanCategory('');
        setLoanIcon('');

        // Optionally, you might want to refresh the loan list after adding a new loan
        fetchLoanData(); // Call fetchLoanData to refresh the list
    };


    const handleDeleteLaonCategory = async(loanName) => {
       await deleteLoanCategory(loanName, token)
        fetchLoanData();
    }

    const handleLoanIconChange = (e) => {
        setLoanIcon(e.target.value);
        console.log('Loan Icon submitted:', e.target.value);
    };

    return (
        <Container maxWidth="lg">
            <Paper style={{ padding: 16, marginBottom: 16 }}>
                <Typography variant="h4" gutterBottom>
                    Create Loan Application
                </Typography>

                {/* Form starts here */}
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Loan Category"
                        name="loanCategory"
                        value={loanCategory}
                        onChange={(e) => setLoanCategory(e.target.value)}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                    />
                    <TextField
                        label="Icon URL"
                        name="icon"
                        value={loanIcon}
                        onChange={handleLoanIconChange} // Corrected here
                        fullWidth
                        margin="normal"
                        variant="outlined"
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        style={{ marginTop: 16 }}
                    >
                        Save
                    </Button>
                </form>
            </Paper>

            <Paper style={{ padding: 16 }}>
                <Typography variant="h5" gutterBottom>ALL Loan Category List</Typography>

                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>S.No</TableCell>
                            <TableCell>Category</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loanList.map((row, index) => (
                            <TableRow key={row.id}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{row.categoryName}</TableCell>
                                <TableCell>
                                    <IconButton
                                        onClick={() => handleDeleteLaonCategory(row.categoryName)}
                                        aria-label="Delete"
                                    >
                                        <DeleteForeverIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </Container>
    );
};

export default LoanCategory;
