
import { apiConnector } from "../ApiConnection"
import { AdminEndPoint } from "../apis"
import axios from "axios";
import toast from "react-hot-toast"

const { ADD_LOAN_WITH_ICON_API, EdIT_LOAN_CATEGORY_API, GET_ALL_LOAN_CATEGORY_API,GET_ALL_LOAN_SUGGESTION_API } = AdminEndPoint;



export const deleteFinencer = async (id, token) => {

    const toastId = toast.loading("Loading...");
    try {
        const response = await axios.delete(
            `https://financebazaar.vip/api/v1/admin/delete-financer-account/${id}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            }
        );
        console.log("DELETE Finercers API RESPONSE............", response);
        if (response?.status!==200) {
            throw new Error("Could Not Delete Finercers");
        }
        toast.success("Finercers Deleted");
    } catch (error) {
        console.log("DELETE Finercers API ERROR............", error);
        toast.error(error.response?.data?.message || error.message);
    } finally {
        toast.dismiss(toastId);
    }
};


export async function editLoanApplication(formData, token) {
    let result = []
    const toastId = toast.loading("Loading..")
    try {
        const response = await apiConnector("POST", EdIT_LOAN_CATEGORY_API, formData,
            {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            })

        console.log("EDIT Loan API RESPONSE............", response)
        if (!response?.data?.success) {
            throw new Error("Could Not Update Loan Details")
        }
        toast.success("Loan Details Updated Successfully")
        result = response?.data?.data

    } catch (error) {
        console.log("EDIT Laon API ERROR............", error)
        toast.error(error.message)
    }
    toast.dismiss(toastId)
    return result

}

export async function addLoan(formData, token) {
    let result = []
    const toastId = toast.loading("Loading..")
    try {
        const response = await apiConnector("POST", ADD_LOAN_WITH_ICON_API, formData,
            {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            })

        console.log(" Loan API RESPONSE............", response)
        if (response?.status !== 201) {
            throw new Error("Could Not Add Loan")
        }
        toast.success("Loan Added  Successfully")
        result = response?.data?.data

    } catch (error) {
        console.log("EDIT Laon API ERROR............", error)
        toast.error(error.message)
    }
    toast.dismiss(toastId)
    return result

}


export const FetchAllLoanCategory = async () => {

    let result = [];

    try {
        const response = await apiConnector("GET", GET_ALL_LOAN_CATEGORY_API, null)
        console.log("ALL categories Response.....", response)


        if (response.status !== 200) {
            throw new Error(response.message)
        }
        result = response?.data?.data

    } catch (error) {
        console.error("error While Fetiching the Categories data", error.message)
        toast.error("Couldn't fetched Categories")
    }
    return result
}





export const deleteLoanCategory = async (loanName, token) => {

    console.log(`https://financebazaar.vip/api/v1/category/delete-loan-category/${loanName}`, "token=>", token);
    const toastId = toast.loading("Loading...");
    try {
        const response = await axios.delete(
            `https://financebazaar.vip/api/v1/category/delete-loan-category/${loanName}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            }
        );
        
        console.log("DELETE LOAN API RESPONSE............", response);

        if (response?.status!==200) {
            throw new Error("Could Not Delete LOAN");
        }

        toast.success("LOAN Deleted");
    } catch (error) {
        console.log("DELETE LOAN API ERROR............", error);
        toast.error(error.response?.data?.message || error.message);
    } finally {
        toast.dismiss(toastId);
    }
};





export async function editLoanApplicationData(formData,id, token) {
    let result = []
    const toastId = toast.loading("Loading..")
    try {
        const response = await axios.patch(
            `https://financebazaar.vip/api/v1/admin/edit-loan-application/${id}`,
            formData,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            }
        );

        console.log("EDIT Loan API RESPONSE............", response)
        if (response?.status!==200) {
            throw new Error("Could Not Update Loan Details")
        }
        toast.success("Loan Details Updated Successfully")
        result = response?.data?.data

    } catch (error) {
        console.log("EDIT Laon API ERROR............", error)
        toast.error(error.message)
    }
    toast.dismiss(toastId)
    return result

}

// loan-category-suggestion/all-suggestion


export const FetchAllLoanSuggestion = async () => {

    let result = [];

    try {
        const response = await apiConnector("GET", GET_ALL_LOAN_SUGGESTION_API, null)
        console.log("ALL categories Response.....", response)


        if (response.status !== 200) {
            throw new Error(response.message)
        }
        result = response?.data?.data

    } catch (error) {
        console.error("error While Fetiching the Suggestion data", error.message)
        toast.error("Couldn't fetched Suggestion")
    }
    return result
}


export const deleteLoanSuggestion = async (loanName, token) => {

    const toastId = toast.loading("Loading...");
    try {
        const response = await axios.delete(
            `https://financebazaar.vip/api/v1/loan-category-suggestion/delete-suggestion/${loanName}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            }
        );
        
        console.log("DELETE LOAN API RESPONSE............", response);

        if (response?.status!==200) {
            throw new Error("Could Not Delete LOAN");
        }

        toast.success("LOAN Deleted");
    } catch (error) {
        console.log("DELETE LOAN API ERROR............", error);
        toast.error(error.response?.data?.message || error.message);
    } finally {
        toast.dismiss(toastId);
    }
};