import { setLoading, setToken } from "../../Slices/Auth"
import { apiConnector } from "../ApiConnection"
import { AuthEndPoint } from "../apis"
import toast from "react-hot-toast"
import axios from "axios"
import { setUser } from "../../Slices/Profile"
const { LOGIN_API } = AuthEndPoint


export const getLogin = (email, password, navigate) => {
    return async (dispatch) => {
        const toastID = toast.loading("Loading...")
        try {
            const response = await apiConnector("POST", LOGIN_API, { email, password })
            console.log("Login Response....", response)
            if (response.status != '200') {
                throw new Error(response.data.message)
            }
            toast.success("LOGIN SUCCESFULL")
            dispatch(setToken(response.data.admin.token))
            dispatch(setUser(response.data.admin))
            localStorage.setItem("token",response?.data?.admin?.token)
            localStorage.setItem("user", JSON.stringify(response?.data?.admin))
            navigate("/Admin/Dashboard")
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const reserror = error.response.data
                console.error('AxiosError:', error.response?.data || 'No response data');
            } else {
                console.error('Error:', error.message);
            }
            toast.error("Failed to Login")
        }
        toast.dismiss(toastID)
    }

}