import React, { useState, useEffect } from 'react';
import { Table, TableHead, TableBody, TableRow, Container, Paper, TableCell, IconButton, Typography, TableFooter, Button, Modal, Box } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { baseUrl } from '../../../utils/baseUrl';
import LoanApplicationForm from './editLoanAppliForm';
import { setLoanData } from '../../../Slices/EditLoanData';
import { useDispatch, useSelector } from 'react-redux';

const LoanApplicationsList = () => {
    const navigate = useNavigate();
    const [loanApplicationList, setLoanApplicationList] = useState([]);
    const [page, setPage] = useState(1); // Track current page
    const [hasMore, setHasMore] = useState(true); // Track if more data exists
    const [isLoading, setIsLoading] = useState(false); // Track loading state
    const [openModal, setOpenModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null); // State to store selected row data

    const { token } = useSelector((state) => state.auth)

    const dispatch = useDispatch();
    const handleAddFinancer = () => {
        navigate('/admin/dashboard/create-loan-application');
    };
    const getAllLoanApplications = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${baseUrl}/admin/list-loan-application?page=${page}&limit=10`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch loan applications');
            }
            const data = await response.json();
            console.log("all data ", data);
            setLoanApplicationList(data.data);
            setHasMore(data.data.length === 10);
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {

        getAllLoanApplications();
    }, [page]);

    const handleViewLoanApplication = (id) => {
        const url = `/Admin/Dashboard/view-loan-application-details/${id}`;
        window.open(url, '_blank');
    };

    const handleEditLoanApplication = (row) => {
        setSelectedRow(row);
        dispatch(setLoanData(row));
        setOpenModal(true);
    };

    const handleModalClose = () => {
        setOpenModal(false);
        setSelectedRow(null); // Reset the selected row data when modal closes
        getAllLoanApplications()
    };


    const handleNextPage = () => {
        if (hasMore) {
            setPage((prevPage) => prevPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (page > 1) {
            setPage((prevPage) => prevPage - 1);
        }
    };

    return (
        <Container>
            <Paper style={{ padding: 16, position: 'relative' }}>
                <Typography variant="h5" gutterBottom>Manage Loan Applications</Typography>

                <div
                    onClick={handleAddFinancer}
                    style={{
                        position: 'absolute',
                        top: 10,
                        cursor: 'pointer',
                        right: 16,
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: '#1976d2',
                        padding: '8px 16px',
                        borderRadius: '8px',
                        color: 'white'
                    }}
                >
                    <IconButton aria-label="add" style={{ color: 'white' }}>
                        <AddIcon />
                    </IconButton>
                    <Typography variant="body1" style={{ marginLeft: 8, color: 'white' }}>Add Loan Application</Typography>
                </div>

                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><Typography>Name</Typography></TableCell>
                            <TableCell><Typography>Mobile</Typography></TableCell>
                            <TableCell><Typography>Loan Category</Typography></TableCell>
                            <TableCell><Typography>Created Date</Typography></TableCell>
                            <TableCell><Typography>Status</Typography></TableCell>
                            <TableCell><Typography>Edit</Typography></TableCell>
                            <TableCell><Typography>View</Typography></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loanApplicationList && loanApplicationList.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>{row.fullName}</TableCell>
                                <TableCell>{row.mobile}</TableCell>
                                <TableCell>{row.loanCategory}</TableCell>
                                <TableCell>
                                    {new Date(row.createdAt).toLocaleDateString('en-GB', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric'
                                    })}
                                </TableCell>

                                <TableCell>{row.status}</TableCell>
                                <TableCell>
                                    <IconButton color="primary" onClick={() => handleEditLoanApplication(row)}>
                                        <EditOutlinedIcon />
                                    </IconButton>
                                </TableCell>
                                <TableCell>
                                    <IconButton color="primary" onClick={() => handleViewLoanApplication(row.id)}>
                                        <VisibilityIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={5}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Button
                                        variant="contained"
                                        onClick={handlePreviousPage}
                                        disabled={page === 1 || isLoading}
                                    >
                                        Previous
                                    </Button>
                                    <Button
                                        variant="contained"
                                        onClick={handleNextPage}
                                        disabled={!hasMore || isLoading}
                                    >
                                        Next
                                    </Button>
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
                <Modal
                    open={openModal}
                    onClose={handleModalClose}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                >
                    <Box sx={{
                        position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
                        width: 500, bgcolor: 'background.paper', boxShadow: 24, p: 4
                    }}>
                        <LoanApplicationForm formData={selectedRow} onClose={handleModalClose} />
                    </Box>
                </Modal>

            </Paper>
        </Container>
    );
};

export default LoanApplicationsList;
