import React, { useEffect, useState } from 'react';
import { Box, FormControl, InputLabel, TextField, Button, Typography, MenuItem, Select, InputAdornment, IconButton } from '@mui/material';
import { AddCircle } from '@mui/icons-material';
import { baseUrl } from '../../../utils/baseUrl';

const LoanApplicationForm = () => {
  const [loanCategories, setLoanCategories] = useState([])
  const [formData, setFormData] = useState({
    fullName: '',
    mobile: '',
    aadharCardName: '',
    panCardName: '',
    aadharCardFile: null,
    panCardFile: null,
    loanCategory: '',
    requiredLoanAmount: '',
    additionalDocuments: [],
  });

  useEffect(() => {
    const fetchLoanCategory = async () => {
      try {
        const response = await fetch(`${baseUrl}/category/all-loan-category-list`, {
          method: 'GET',
        });

        if (!response.ok) {
          throw new Error('Failed to submit loan application');
        }
        const data=await response.json()
        setLoanCategories(data.data)
      } catch (error) {
        console.error('Error:', error);
      }
    }

    fetchLoanCategory()
  }, [])

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e, field) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({
        ...formData,
        [`${field}Name`]: file.name,
        [`${field}File`]: file,
      });
    }
  };

  const handleAdditionalFileChange = (e, index) => {
    const file = e.target.files[0];
    if (file) {
      const newDocs = [...formData.additionalDocuments];
      newDocs[index] = { name: file.name, file: file };
      setFormData({ ...formData, additionalDocuments: newDocs });
    }
  };

  const addDocumentField = () => {
    if (formData.additionalDocuments.length < 10) {
      setFormData({
        ...formData,
        additionalDocuments: [...formData.additionalDocuments, { name: '', file: null }],
      });
    }
  };

  const handleSubmit = async () => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('fullName', formData.fullName);
      formDataToSend.append('mobile', formData.mobile);
      formDataToSend.append('aadharCard', formData.aadharCardFile);
      formDataToSend.append('panCard', formData.panCardFile);
      formDataToSend.append('loanCategory', formData.loanCategory);
      formDataToSend.append('requiredLoanAmount', formData.requiredLoanAmount);

      formData.additionalDocuments.forEach((doc) => {
        if (doc.file) {
          formDataToSend.append('documents', doc.file);
        }
      });

      const response = await fetch(`${baseUrl}/admin/apply-new-application`, {
        method: 'POST',
        body: formDataToSend,
      });

      if (!response.ok) {
        throw new Error('Failed to submit loan application');
      }

      alert('Loan application submitted successfully!');
      setFormData({
        fullName: '',
        mobile: '',
        aadharCardName: '',
        panCardName: '',
        aadharCardFile: null,
        panCardFile: null,
        loanCategory: '',
        requiredLoanAmount: '',
        additionalDocuments: [],
      });
    } catch (error) {
      console.error('Error:', error);
      alert(`Error: ${error.message}`);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '90vh',
        padding: '0px 20px',
      }}
    >
      <Box
        sx={{
          maxWidth: 500,
          width: '100%',
          padding: 4,
          borderRadius: 2,
          backgroundColor: 'white',
          boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
        }}
      >
        <Typography variant="h5" textAlign="center" mb={4}>
          Loan Application
        </Typography>

        <TextField
          fullWidth
          label="Full Name"
          name="fullName"
          value={formData.fullName}
          onChange={handleInputChange}
          sx={{ mb: 2, borderRadius: '8px', '& .MuiOutlinedInput-root': { borderRadius: '8px' } }}
        />

        <TextField
          fullWidth
          label="Mobile Number"
          name="mobile"
          value={formData.mobile}
          onChange={handleInputChange}
          sx={{ mb: 2, borderRadius: '8px', '& .MuiOutlinedInput-root': { borderRadius: '8px' } }}
        />

        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <TextField
            fullWidth
            label="Aadhar Card"
            name="aadharCardName"
            value={formData.aadharCardName || ''}
            InputLabelProps={{ shrink: !!formData.aadharCardName }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="contained"
                    color="primary"
                    component="label"
                    sx={{ marginLeft: 1 }}
                  >
                    Upload
                    <input type="file" hidden onChange={(e) => handleFileChange(e, 'aadharCard')} />
                  </Button>
                </InputAdornment>
              ),
            }}
            sx={{ mr: 1, borderRadius: '8px', '& .MuiOutlinedInput-root': { borderRadius: '8px' } }}
          />
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <TextField
            fullWidth
            label="PAN Card"
            name="panCardName"
            value={formData.panCardName || ''}
            InputLabelProps={{ shrink: !!formData.panCardName }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="contained"
                    color="primary"
                    component="label"
                    sx={{ marginLeft: 1 }}
                  >
                    Upload
                    <input type="file" hidden onChange={(e) => handleFileChange(e, 'panCard')} />
                  </Button>
                </InputAdornment>
              ),
            }}
            sx={{ mr: 1, borderRadius: '8px', '& .MuiOutlinedInput-root': { borderRadius: '8px' } }}
          />
        </Box>

        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel htmlFor="loanCategory">Loan Category</InputLabel>
          <Select
            id="loanCategory"
            name="loanCategory"
            value={formData.loanCategory}
            onChange={handleInputChange}
            sx={{ borderRadius: '8px' }}
          >
            {loanCategories.map((category) => (
              <MenuItem key={category.categoryName} value={category.categoryName}>
                {category.categoryName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          fullWidth
          type="number"
          label="Required Loan Amount"
          name="requiredLoanAmount"
          value={formData.requiredLoanAmount}
          onChange={handleInputChange}
          sx={{ mb: 4, borderRadius: '8px', '& .MuiOutlinedInput-root': { borderRadius: '8px' } }}
        />

        {/* Additional Document Uploads */}
        <Typography variant="h6" mb={2}>Additional Documents</Typography>
        {formData.additionalDocuments.map((doc, index) => (
          <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <TextField
              fullWidth
              label={`Document ${index + 1}`}
              value={doc.name || ''}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      variant="contained"
                      color="primary"
                      component="label"
                      sx={{ marginLeft: 1 }}
                    >
                      Upload
                      <input type="file" hidden onChange={(e) => handleAdditionalFileChange(e, index)} />
                    </Button>
                  </InputAdornment>
                ),
              }}
              sx={{ mr: 1, borderRadius: '8px', '& .MuiOutlinedInput-root': { borderRadius: '8px' } }}
            />
          </Box>
        ))}

        <IconButton color="primary" onClick={addDocumentField} disabled={formData.additionalDocuments.length >= 10}>
          <AddCircle />
        </IconButton>

        <Button variant="contained" color="primary" fullWidth onClick={handleSubmit}>
          Apply
        </Button>
      </Box>
    </Box>
  );
};

export default LoanApplicationForm;
